import * as React from 'react';
import { Create, FormTab, TabbedForm, TextInput, required,useGetOne,BooleanInput, ImageInput, ImageField, ReferenceInput, AutocompleteInput, useNotify, useRedirect, useRecordContext,useCreateSuggestionContext,
    useCreate,} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { Card, Dialog,DialogContent, TextField as MUITextField,Button as MUIButton, DialogActions, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';


//import { ProductEditDetails } from './ProductEditDetails';

//const filterToQuery = (searchText: string) => ({ name_ilike: `%${searchText}`});

const RecommendationCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    
    const onSuccess = (data: any) => {
        notify('ra.notification.created', { messageArgs: { smart_count: 1 } });
        redirect(`/posts/${data.post}`);
    };

    const OptionRenderer = () => {
    const record = useRecordContext();
    const { data, isLoading } = useGetOne('brands', {
        id: record.brand
    });
    //console.log(data)
    //const listContext = useList({ data, isLoading });

    return (
        <span style={{display:"flex",width:"100%"}}>
            <div style={{height:100,margin:10,backgroundColor:"blue"}}> <img src={record.content_file} style={{height:"100%"}} /></div>
            <div style={{display:"flex",flexDirection:"column",margin:10}}>
                <p style={{margin:0,padding:0}}>{data?.name}</p>
                <p style={{margin:0,padding:0}}>{record.name}</p>
                <p style={{margin:0,padding:0}}>{record.description} </p>
            </div>
        </span>
    );
};

    const inputText = (choice: { name: any; description: any; }) => `${choice.name}`;
    const matchSuggestion = (filter: string, choice: { name: string, description:any }) => {
    return (
        choice.name.toLowerCase().includes(filter.toLowerCase())|| (choice.description && choice.description.toLowerCase().includes(filter.toLowerCase()))
    );
};  


const CreateProduct = () => {
    const { filter, onCancel,onCreate} = useCreateSuggestionContext();
    const redirect = useRedirect();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        redirect(`/products/create`);
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    Click en aceptar para ser redirigido a Crear Productos
                </DialogContent>
                <DialogActions>
                    <MUIButton type="submit">Aceptar</MUIButton>
                    <MUIButton onClick={onCancel}>Cancel</MUIButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};


    return (
        <Create mutationOptions={{onSuccess}}>
            <TabbedForm defaultValues={{ store: 1 }}>
                <FormTab
                    label="resources.products.tabs.image"
                    sx={{maxWidth:window.innerWidth}}
                >
                    <ReferenceInput source="post" reference="posts" sx={{  }}>
                        <AutocompleteInput validate={required()} sx={{  }}  fullWidth={true}/>
                    </ReferenceInput>
                    <ReferenceInput source="product" reference="products"  >
                        <AutocompleteInput validate={required()}   fullWidth={true}  optionText={<OptionRenderer />} inputText={inputText} matchSuggestion={matchSuggestion}  create={<CreateProduct/>} TextFieldProps={{
                            placeholder: 'Comienza escribiendo para buscar o crear un producto nuevo ',}} />
                    </ReferenceInput>
                    <BooleanInput label="Es exacta ? " source="is_exact" />

                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default RecommendationCreate;