import * as React from 'react';
import { Card, CardMedia } from '@mui/material';
import { Box, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { Post, PostContent } from '../types';
import ContentCarousel from './ContentCarousel';


const Content = (record: PostContent) => {
    return (
        <Card sx={{ display: 'inline-block' }}>
            <CardMedia
                component='img'
                image={record.content_file}
                alt=""
                //sx={{ maxWidth: '42em', maxHeight: '15em' }}
            />
        </Card>
    );
};

const Contents = () => {
    const record = useRecordContext<Post>();
    if (!record) return null;
    const contents = record.contents;
    return(
        <ContentCarousel data={record} size={400}/>
    )
};

export default Contents;