import * as React from 'react';
import { useGetList,FilterButton,ExportButton,List,Datagrid, Create, FormTab, TabbedForm,useInput,TextField,SimpleFormIterator, TextInput, required,useGetOne,BooleanInput,ReferenceArrayInput, ImageInput, ImageField, ReferenceInput, AutocompleteInput, useNotify, useRedirect, useRecordContext, SelectArrayInput, TopToolbar, ReferenceField, ReferenceArrayField, SingleFieldList, ChipField, ArrayField, CheckboxGroupInput } from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';


//const filterToQuery = (searchText: string) => ({ name_ilike: `%${searchText}`});

const AssignationsCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    
    const onSuccess = (data: any) => {
        notify('ra.notification.created', { messageArgs: { smart_count: 1 } });
        redirect(`/benefits/${data.benefit}`);
    };

    const OptionRenderer = () => {
    const record = useRecordContext();
    const { data, isLoading } = useGetOne('brands', {
        id: record.brand
    });
    //console.log(data)
    //const listContext = useList({ data, isLoading });

    return (
        <span style={{display:"flex",width:"100%"}}>
            <div style={{height:100,margin:10,backgroundColor:"blue"}}> <img src={record.content_file} style={{height:"100%"}} /></div>
            <div style={{display:"flex",flexDirection:"column",margin:10}}>
                <p style={{margin:0,padding:0}}>{data?.name}</p>
                <p style={{margin:0,padding:0}}>{record.name}</p>
                <p style={{margin:0,padding:0}}>{record.description} </p>
            </div>
        </span>
    );
};

    const inputText = (choice: { name: any; description: any; }) => `${choice.name}`;
    const matchSuggestion = (filter: string, choice: { name: string, description:any }) => {
    return (
        choice.name.toLowerCase().includes(filter.toLowerCase())|| (choice.description && choice.description.toLowerCase().includes(filter.toLowerCase()))
    );
};

    


    return (
        <Create mutationOptions={{onSuccess}}>
            <TabbedForm defaultValues={{ store: 1 }}>
                <FormTab
                    label="resources.products.tabs.image"
                    sx={{maxWidth:window.innerWidth}}
                >
                    <ReferenceInput  source="benefit" reference="benefits" sx={{  }}>
                        <AutocompleteInput  disabled validate={required()} sx={{  }}  fullWidth={true}/>
                    </ReferenceInput>
                    <ReferenceInput source="user" reference="users"  >
                        <AutocompleteInput validate={required()}   fullWidth={true} />
                    </ReferenceInput>
                  
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default AssignationsCreate;