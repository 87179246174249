import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import AssignationsList from './AssignationsList';
import AssignationsCreate from './AssignationsCreate';
//import AssignationEdit from './AssignationsEdit';

export default {
    list: AssignationsList,
    create: AssignationsCreate,
    //edit: AssignationEdit,
    icon: DynamicFeedIcon,
}