import * as React from 'react';
import {
    Datagrid,
    DateField,
    Edit,
    EditButton,
    FormTab,
    Pagination,
    ReferenceManyField,
    required,
    TabbedForm,
    TextField,
    TextInput,
    useRecordContext,
    useGetManyReference,
    useTranslate,
    ImageField,
    UrlField,
    DateInput,
    useGetOne,
    ReferenceField,
    DeleteButton,
    CreateButton,
    Button,
    Link,
    SelectArrayInput,
    BooleanInput,
    NumberInput,
    FileInput,
    ReferenceInput,
    AutocompleteInput,
    useInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    useCreateSuggestionContext,
    useCreate,
    useNotify,
    useRedirect
    

} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { PreviewVideos } from './ContentCarousel';
import Contents from './Contents';
import BrandLinkField from './BrandLinkField';
import { Post, Recommendation } from '../types';
import { Card, Dialog,DialogContent, TextField as MUITextField,Button as MUIButton, DialogActions, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Autocomplete from "react-google-autocomplete";
import { TagsInput } from "react-tag-input-component";
import AddIcon from '@mui/icons-material/Add';

const ContentPreviewField = (props: any) => {
    const record = useRecordContext(props);
    //console.log(record);
    if (record.rawFile?.type.includes("image")){
        return (<img src={record.src} width={250}/>);
    }
    else if (record.rawFile?.type.includes("video")){
        return (<PreviewVideos item={record} image_size={250}/>)
    }
    else {
        return (<div/>);
    }
    }

const PostTitle = () => {
    const record = useRecordContext<Post>();
    const username = `${record.user.first_name} ${record.user.last_name}`;
    return record ? <span>Post id:{record.id} by {username}"</span> : null;
};

const HitRecommendation = () => {
    const record = useRecordContext<Recommendation>();
    const { data, isLoading, error, refetch } = useGetOne(
        'recommendations',
        { id: record.id, meta: { action: 'hits' } }
    )
    const defaultStartDate = new Date();
    defaultStartDate.setDate(defaultStartDate.getDate() - 7);
    const defaultEndDate = new Date();

    return (
        <Card>
            {/*
            <DateInput source="start_date" defaultValue={defaultStartDate} />
            <DateInput source="end_date" defaultValue={defaultEndDate} />
            */}

        </Card>
    );
};

const RecommendationDetails = () => {
    const record = useRecordContext<Recommendation>();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Link del Producto</TableCell>
                            <TableCell>Marca</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <ReferenceField source='product' reference='products'>
                                    <TextField source="link" />
                                </ReferenceField>
                            </TableCell>
                            <TableCell>
                                <TextField source="product.brand" />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card>
                    <HitRecommendation />
                </Card>
            </Grid>

        </Grid>
    );
};

const AddNewRecommendationButton = () => {
    const record = useRecordContext();

    return (
        <Button
            component={Link}
            to={{
                pathname: "/recommendations/create",
                state: { record: { post: record.id } }
            }}
        >
            <AddIcon />
        </Button>
    )
};

    type Props = {
        source: string;
        label?: string;
    }


   

const PlaceInput = (props :Props) => {
    const { id, field, fieldState } = useInput(props);
    //console.log(id, field, fieldState)

    return (
        <Autocomplete 
                    style={{
                        width: "100%",
                        height: 50,
                        padding: 5,
                        backgroundColor: "#e0e0e3",
                        border:"none",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                        borderRadius:"10px 10px 0 0 ",
                        marginBottom:10,
                      }}
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                    options={{ types: ["establishment"], fields: ["place_id","url","name"] }}
                    onPlaceSelected={(place) =>  {
                        if(place)
                            field.onChange(place); 
                        }}
        />
    );
    };


    const MyTagsInput = (props :Props) => {
    const { id, field, fieldState } = useInput(props);
    const [myValue,setMyValue]= React.useState([]);
    return (
        <div style={{marginBottom:10,width:"100%"}}>
        <TagsInput
                value={myValue}
                onChange={(e) => field.onChange(e)}
                name="categories"
                placeHolder="#hashtags"
                separators={[" ","Enter"]}
                isEditOnRemove={true}
       />
       </div>
    );
    };

    const optionRenderer = (choice: { first_name: any; last_name: any; }) => `${choice.first_name} ${choice.last_name}`;
    const matchSuggestion = (filter: string, choice: { username: string, first_name: string, last_name :string }) => {
        return (
            choice?.username?.toLowerCase().includes(filter.toLowerCase())|| choice?.first_name?.toLowerCase().includes(filter.toLowerCase())|| choice?.last_name?.toLowerCase().includes(filter.toLowerCase())
        );
        }

    const matchSuggestionName = (filter: string, choice: {name: string }) => {
            return (
                choice?.name?.toLowerCase().includes(filter.toLowerCase())
            );
            }   
            
            
const CreateTag = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [value, setValue] = React.useState(filter || '');
    const [create] = useCreate();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        create(
            'categories',
            {
                data: {
                    name: value,
                },                
            },
            {
                onSuccess: (data) => {
                    setValue('');
                    onCreate(data);
                },
            }
        );
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <MUITextField
                        label="Nueva Categoria"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <MUIButton type="submit">Save</MUIButton>
                    <MUIButton onClick={onCancel}>Cancel</MUIButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};


const CreateNewUser = () => {
    const { filter, onCancel,onCreate} = useCreateSuggestionContext();
    const redirect = useRedirect();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        redirect(`/users/create`);
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    Click en guardar para ser redigirido a crear usuario
                </DialogContent>
                <DialogActions>
                    <MUIButton type="submit">Save</MUIButton>
                    <MUIButton onClick={onCancel}>Cancel</MUIButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const PostEdit = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data : any) => {
        notify(`Changes saved`);
        redirect(`/posts/${data.id}`);
    };
    return (
    <Edit  mutationOptions={{ onSuccess }}>
        <TabbedForm>
            <FormTab
                label="resources.products.tabs.image"
            //sx={{ maxWidth: '40em' }}
            >
                    <Contents />
                        <AddNewRecommendationButton />
                        <ReferenceManyField
                            reference="recommendations"
                            target="post"
                            pagination={<Pagination />}
                        >
                            {/*<AddRecommendation />*/}
                            {/*<CreateButton resource="recommendations" />*/}
                            <Datagrid
                                /*sx={{
                                    width: '100%',
                                    '& .column-comment': {
                                        maxWidth: '20em',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    },
                                }}*/
                                rowClick="expand"
                                expand={<RecommendationDetails />}
                                expandSingle
                                bulkActionButtons={false}
                            >
                                <ReferenceField source='product' reference='products'>
                                    <ImageField source="content_file"
                                        sx={{
                                            width: '2%'
                                        }} />
                                </ReferenceField>
                                <ReferenceField source='product' reference='products'>
                                    <TextField source="name" />
                                </ReferenceField>
                                <ReferenceField label="" source='product' reference='products'>
                                    <ReferenceField source='brand' reference='brands'>
                                        <BrandLinkField source='name' />
                                    </ReferenceField>
                                </ReferenceField>
                                <DateField source="created_at" />
                                <DeleteButton />
                            </Datagrid>
                        </ReferenceManyField>
                    
            </FormTab>
            <FormTab
            label="resources.posts.edit">
                <Contents />
                    
                    <ReferenceArrayInput source="albums" reference="albums">
                        <AutocompleteArrayInput  validate={required()} label="Albums" fullWidth={true}  optionText='name' matchSuggestion={matchSuggestionName} /> 
                    </ReferenceArrayInput>

                    <ReferenceInput source="user" reference="users" sx={{ }}>
                    <AutocompleteInput validate={required()} sx={{  }}  fullWidth={true}  optionText={optionRenderer} matchSuggestion={matchSuggestion} create={<CreateNewUser/>}  />
                    </ReferenceInput>

                    <BooleanInput label="Es hot? " source="is_hot" defaultValue={true}  />
                    <BooleanInput label="Es global? " source="is_global" defaultValue={true} />
                    <BooleanInput label="Es visible? " source="is_visible"  defaultValue={true} />
                    <RichTextInput source="description" />
                    <ReferenceArrayInput source="categories" reference="categories">
                        <AutocompleteArrayInput label="categories" fullWidth={true}   optionText='name'  matchSuggestion={matchSuggestionName} create={<CreateTag />} TextFieldProps={{
                            placeholder: 'Comienza escribiendo para buscar o crear categorias ',}} /> 
                    </ReferenceArrayInput>

                    <p> Solo completar si es categoria travel </p>
                   
                    <NumberInput source="grade"  min={0} max={5} step={0.5} defaultValue={null}/>
                    <PlaceInput source="place" label="Place"   />
                   
                    {/* <FileInput source="contents" validate={required()} multiple sx={{"& .RaFileInput":{width:300
                    }, display:"flex",flexDirection:"row",flexFlow:"wrap","& .RaFileInput-dropZone":{
                        width:"100%"
                    }}}
                    >
                            <ContentPreviewField /> 
                    </FileInput> */}
                    
            </FormTab>
        </TabbedForm>
    </Edit>
    )
                };

const req = [required()];



export default PostEdit;