import React, { useEffect, useState, useRef } from "react";
import Carousel from "react-simply-carousel";
import ReactPlayer from "react-player";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";



export function PreviewVideos(props){
  const image_size = props.image_size;
  const item  = props.item;
  // const objectUrl = URL.createObjectURL(item);
  const objectUrl = item.src
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <div
      style={{
        width: image_size,
        height: (image_size * 16) / 9,
        borderRadius: 10,
        overflow: "hidden",
      }}
    >
      <ReactPlayer
        url={objectUrl}
        playing={isPlaying}
        loop={true}
        volume={0.5}
        width={image_size}
        height={(image_size * 16) / 9}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setIsPlaying(false);
        }}
        onBlur={(e) => setIsPlaying(false)}
      />

      {!isPlaying ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            zIndex: 20,
            width: image_size,
            height: (image_size * 16) / 9,
            top: (-image_size * 16) / 9,
            backgroundColor: "rgba(0,0,0,0.5)",
            border: "None",
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setIsPlaying(true);
          }}
        >
          <PlayArrowIcon style={{ color: "white", fontSize: 50 }} />
          <div
            style={{ width: image_size, color: "white", marginTop: 20 }}
          ></div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );

}



export function ItemsVideo(props) {
  const [isPlaying, setIsPlaying] = useState(false);
  const content_file = props.content;
  const my_id= props.i;
  const activeSlide = props.activeSlide;
  const image_size = props.size 



  useEffect(() => {
    //console.log("MY id ;",my_id,"    Active SLid:", activeSlide);
    if (activeSlide != my_id){
      setIsPlaying(false)
    }
  }, [activeSlide]);

  return (
    <div
      style={{
        width: image_size,
        height: (image_size * 16) / 9,
        borderRadius: 10,
        overflow: "hidden",
      }}
    >
      <ReactPlayer
        url={content_file}
        playing={isPlaying}
        loop={true}
        volume={0.5}
        width={image_size}
        height={(image_size * 16) / 9}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setIsPlaying(false);
        }}
        onBlur={(e) => setIsPlaying(false)}
      />

      {!isPlaying ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            zIndex: 20,
            width: image_size,
            height: (image_size * 16) / 9,
            top: (-image_size * 16) / 9,
            backgroundColor: "rgba(0,0,0,0.5)",
            border: "None",
          }}
          onClick={(e) => {

            e.stopPropagation();
            e.preventDefault();
            setIsPlaying(true);
          }}
        >
          <PlayArrowIcon style={{ color: "white", fontSize: 50 }} />
          <div
            style={{ width: image_size, color: "white", marginTop: 20 }}
          ></div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

export default function ContentCarousel(props) {
  const [activeSlide, setActiveSlide] = useState(0);
  const data = props.data;
  const image_size = props.size; 

  return (
    <Carousel
      containerProps={{
        style: {
          width: image_size,
          alignSelf: "center",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 10,
        },
      }}
      activeSlideIndex={activeSlide}
      onRequestChange={setActiveSlide}
      forwardBtnProps={{
        style: {
          border: "none",
          background: "none",
        },
      }}
      backwardBtnProps={{
        style: {
          border: "none",
          background: "none",
        },
      }}
      dotsNav={{
        show: data.contents.length > 1 ? true : false,
        containerProps: {
          style: {
            position: "relative",
            top: -30,
          },
        },

        itemBtnProps: {
          style: {
            height: 10,
            width: 10,
            margin: 3,
            borderRadius: "100%",
            border: 0,
            background: "rgba(255,255,255,0.5)",
          },
        },
        activeItemBtnProps: {
          style: {
            height: 10,
            width: 10,
            margin: 3,
            borderRadius: "100%",
            border: 0,
            background: "rgba(255,255,255,1)",
          },
        },
      }}
      itemsToShow={1}
      speed={400}
    >
      {data?.contents ? (
        data.contents.map((content,i) => (
          <button
            style={{
              border: "none",
              backgroundColor: "rgba(0,0,0,1)",
              borderRadius: 5,
              margin: 0,
              padding: 0,
              width: image_size,
            }}
          >
            {content?.content_type?.includes("video") ? (
              <ItemsVideo content={content.content_file} id={data?.id} i={i} activeSlide={activeSlide}  size={image_size}/>
            ) : (
              <img
                style={{
                  width: image_size,
                  borderWidth: 0,
                  borderRadius: 5,
                }}
                src={content.content_file}
                alt="image"
              />
            )}
          </button>
        ))
      ) : (
        <div />
      )}
    </Carousel>
  );
}
