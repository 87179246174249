import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOnOutlined';
import MailIcon from '@mui/icons-material/MailOutline';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import {
    FilterList,
    FilterListItem,
    FilterLiveSearch,
    SavedQueriesList,
    useGetList,
} from 'react-admin';
import {
    endOfYesterday,
    startOfWeek,
    subWeeks,
    startOfMonth,
    subMonths,
} from 'date-fns';

import { Role, Segment } from '../types';

const Aside = () => {
    const { data: roles } = useGetList<Role>('roles', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'name', order: 'ASC' },
    });
    const { data: segments } = useGetList<Segment>('roles', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'name', order: 'ASC' },
    });

    return (
        <Card
            sx={{
                display: {
                    xs: 'none',
                    md: 'block',
                },
                order: -1,
                flex: '0 0 15em',
                mr: 2,
                mt: 8,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                <FilterLiveSearch />

                <SavedQueriesList />


                <FilterList
                    label="resources.users.filters.role"
                    icon={<LocalOfferIcon />}
                >
                    {roles ?
                        roles.map(role => (
                            <FilterListItem
                                label={role.name}
                                key={role.id}
                                value={{ roles: role.id }}
                            />
                        ))
                        : null}
                </FilterList>
                <FilterList
                    label="resources.users.filters.segment"
                    icon={<LocalOfferIcon />}
                >
                    {segments ?
                        segments.map(segment => (
                            <FilterListItem
                                label={segment.name}
                                key={segment.id}
                                value={{ segments: segment.id }}
                            />
                        ))
                        : null}
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default Aside;